import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const PolitykaPage = () => (
    <Layout>
        <SEO title="Polityka prywatności | Świecki Kancelaria"/>
        <section className="polityka">
            <div className="polityka__container">
                <h1 className="polityka__title">Polityka prywatności</h1>
                <p className="polityka__text polityka__text--italic">
                    Wysyłając wiadomość na adres mailowy kancelaria@swiecki.com.pl bądź pocztą na
                    adres korespondencyjny Administratora, wyrażasz zgodę na przetwarzanie przez
                    Administratora danych osobowych w celu udzielenia odpowiedzi na postawione
                    pytania oraz możliwości komunikacji w związku z wysłaną wiadomością.
                </p>
                <ul className="polityka__list">
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;1 Administrator danych osobowych</h2>
                        <p className="item__text">
                            Administratorem Twoich danych osobowych jest Adam Świecki prowadzący działalność
                            gospodarczą pod nazwą Świecki Kancelaria Adwokacka Adam Świecki wpisany do
                            Centralnej Ewidencji i Informacji o Działalności Gospodarczej prowadzonej przez
                            Ministra Rozwoju i Finansów, posiadający numer NIP 729-269-94-46, Regon
                            361945123, ul. Narutowicza 16/1, 90-135 Łódź zwany dalej Administratorem.
                            Kontakt z Administratorem możliwy jest poprzez wysłanie wiadomości email na
                            adres kancelaria@swiecki.com.pl lub pisemnie na adres siedziby.
                        </p>
                    </li>
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;2 Cel przetwarzania danych osobowych oraz podstawa prawna przetwarzania
                        </h2>
                        <p className="item__text">
                            1. Administrator przetwarza dane osobowe w oparciu o Rozporządzenie Parlamentu
                            Europejskiego i Rady (UE) 2016/679 z 27.4.2016 r. w sprawie ochrony osób
                            fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                            przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (Dz.Urz.UE z 2016 r.
                            Nr 119, s.1), zwane dalej RODO. Celem przetwarzania danych osobowych jest przede
                            wszystkim:
                            <br/>
                            a) udzielenie odpowiedzi na postawione pytania i możliwość komunikacji, w
                            przypadku udostępnienia przez osobę, której dane dotyczą danych osobowych w
                            korespondencji mailowej lub tradycyjnej – przetwarzanie danych osobowych
                            dokonane zostanie w oparciu o art. 6 ust 1 lit. a RODO,
                            <br/>
                            b) rozstrzygnięcie ewentualnych sporów pomiędzy osobą, której dane dotyczą a
                            Administratorem wynikłych w związku z komunikacją pomiędzy nimi i dochodzone na
                            tej podstawie roszczenia jednej ze stron przed wszystkimi sądami powszechnymi
                            lub organami państwowymi, co stanowi prawnie uzasadniony interes Administratora
                            - przetwarzanie danych osobowych dokonane zostanie w oparciu o art. 6 ust. 1
                            lit. f RODO.
                            <br/>
                            c) działania marketingowe, m.in. wysyłanie do Ciebie newsletterów, analizowaniu sposobu odbioru naszych newsletterów.
                        </p>
                    </li>
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;3 Odbiorcy danych osobowych</h2>
                        <p className="item__text">
                            1. Administrator może przekazywać Twoje dane osobowe podmiotom trzecim w tym
                            przede wszystkim podmiotom obsługującym systemy informatyczne i sprzęt
                            stanowiący wyposażenie kancelarii Administratora, podmiotom świadczącym usługi
                            księgowe, pocztowe, kurierskie, podmiotom świadczącym inne usługi na rzecz
                            Administratora, a także upoważnionym osobom jedynie w zakresie niezbędnym do
                            prawidłowego wykonania przez nich zadania.
                            <br/>
                            2. Administrator może przekazywać Twoje dane osobowe do państwa trzeciego ze
                            względu na zastosowane rozwiązania informatyczne (hosting, przechowywanie plików
                            w chmurze, programy pocztowe itp.) umożliwiające sprawne i możliwie
                            najbezpieczniejsze funkcjonowanie prowadzonej przez Administratora działalności
                            gospodarczej. W przypadku gdy państwo trzecie znajduje się poza obszarem Unii
                            Europejskiej, w celu zapewnienia wymaganego przepisami poziomu ochrony danych
                            osobowych, przekazanie danych osobowych do tego państwa odbywać się będzie w
                            oparciu o Standardowe Klauzule Umowne lub regulacje Privacy Shield zatwierdzone
                            przez Komisję Europejską.
                            <br/>
                            3. Administrator może przekazywać Twoje dane osobowe także dostawcom narzędzi do wysyłki newsletterów oraz analityki ruchu na stronie, podmiotom realizującym usługi hostingu.
                        </p>
                    </li>
                    <li className="polityka__item item">

                        <h2 className="item__title">&#167;4 Okres przechowywania danych osobowych</h2>
                        <p className="item__text">Administrator będzie przetwarzał dane osobowe przez
                            okres umożliwiający udzielenie wyczerpujących odpowiedzi i sprawną komunikację w
                            zakresie dotyczącym wysłanej wiadomości, jak również przez okres niezbędny do
                            zabezpieczenia lub dochodzenia roszczeń oraz obrony przed roszczeniami.</p>
                    </li>
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;5 Uprawnienia osoby, której dane dotyczą</h2>
                        <p className="item__text">
                            1. Wyrażoną zgodę możesz cofnąć w każdym momencie wysyłając wiadomość na adres
                            mailowy kancelaria@swiecki.com.pl bądź pocztą na adres korespondencyjny
                            Administratora. Cofnięcie zgody nie ma wpływu na zgodność z prawem przetwarzania
                            dokonanego przez Administratora na podstawie zgody przed jej cofnięciem.
                            <br/>
                            2. Osobie, której dane dotyczą przysługuje uprawnienie żądania od Administratora
                            dostępu do udostępnionych przez nią danych osobowych, ich sprostowania,
                            usunięcia lub ograniczenia przetwarzania, wniesienia sprzeciwu wobec
                            przetwarzania, a także przenoszenia danych.
                            <br/>
                            3. Osobie, której dane dotyczą przysługuje prawo wniesienia skargi do Prezesa
                            Urzędu Ochrony Danych Osobowych (https://uodo.gov.pl), szczególnie w sytuacji
                            gdy stwierdzi, że jej dane osobowe Administrator przetwarza w sposób
                            nieprawidłowy.
                        </p>
                    </li>
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;6 Wymóg podania danych osobowych</h2>
                        <p className="item__text">Podanie danych osobowych jest dobrowolne, lecz
                            niezbędne do uzyskania odpowiedzi na postawione pytania oraz do komunikacji w
                            związku z wysłaną wiadomością</p>
                    </li>
                    <li className="polityka__item item">
                        <h2 className="item__title">&#167;7 Zautomatyzowane podejmowanie decyzji</h2>
                        <p className="item__text">Dane udostępnione przez osobę, której dotyczą nie będą
                            podlegały zautomatyzowanemu podejmowaniu decyzji oraz profilowaniu.</p>
                    </li>
                </ul>
            </div>
        </section>

    </Layout>
)

export default PolitykaPage